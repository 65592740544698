.modal-dialog {
  height: 90vh;
}
.modal-content {
  position: absolute;
  top: 20%;
}

.custom-date-picker {
  height: 38px;
  max-width: 95%;
  width: inherit;
  /* outline: 0 !important; */
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  /* border-width: 1px; */
  box-sizing: border-box;
  /* padding: 1px 8px; */
}

.next-button {
  gap: 0.25rem;
  transition: gap 0.3s ease;
}

.next-button:hover {
  gap: 1rem;
}
